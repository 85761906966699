body {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #f4f4f4; /* Helles Grau für den Hintergrund */
    margin: 0;
    padding: 0;
}

.header {
    background-color: #2c3e50; /* Dunkelblau für den Header */
    color: white;
    padding: 20px;
}

.navbar ul {
    list-style-type: none;
    padding: 0;
}

.navbar li {
    display: inline;
    margin-right: 20px;
}

.hero {
    background-color: #34495e; /* Etwas helleres Blau für den Hero-Bereich */
    color: white;
    text-align: center;
    padding: 50px 0;
}

.skills {
    padding: 20px;
    background-color: white; /* Weißer Hintergrund für den Fähigkeitenbereich */
}

.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.skill {
    padding: 20px;
    border: 1px solid #ccc; /* Helle Grenze um die Fähigkeiten */
    border-radius: 5px;
    background-color: #ecf0f1; /* Helles Grau für den Hintergrund der Fähigkeiten */
}

.projects, .contact {
    padding: 20px;
    background-color: #ffffff; /* Weiß für Projekte und Kontakt */
}

