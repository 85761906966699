/* Allgemeine Einstellungen */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body {
    background-color: #f2f2f5;
    color: #333;
    font-size: 16px;
    line-height: 1.6;
}

/* Hauptcontainer */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Header */
header {
    background-color: #1a1a1a;
    color: #f2f2f5;
    padding: 20px 0;
    text-align: center;
    border-bottom: 4px solid #0d6efd;
    position: relative;
}

header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #66b2ff;
    transition: color 0.3s ease;
}

header h1:hover {
    color: #b3d8ff;
}

header p {
    font-size: 1.2em;
    color: #cccccc;
}

header nav {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
}

header nav a {
    color: #e0e0e0;
    text-decoration: none;
    font-weight: bold;
    padding: 8px 15px;
    border-radius: 5px;
    transition: color 0.3s ease, background-color 0.3s ease;
}

header nav a:hover {
    color: #1a1a1a;
    background-color: #66b2ff;
}
/* Navigation */
nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    justify-content: center; /* Zentriert die Navigation */
}

nav ul li {
    display: inline;
}

nav ul li a {
    color: #eee; /* Sichtbare Textfarbe */
    text-decoration: none;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Hover-Transition */
}

nav ul li a:hover {
    background-color: #444; /* Hintergrund bei Hover */
    color: #0f0; /* Sichtbare Hover-Textfarbe */
}

/* Hero / Einführung */
.hero {
    background-color: #1a1a1a;
    color: #f2f2f5;
    padding: 40px 20px;
    text-align: center;
}

.hero h2 {
    font-size: 2em;
    color: #66b2ff;
}

.hero p {
    font-size: 1.2em;
    color: #cccccc;
}

/* Skills-Bereich */
.skills {
    margin-top: 30px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.skills h3 {
    text-align: center;
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
}

.skills-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
}

.skill {
    background-color: #e0e0e0;
    padding: 15px;
    border-radius: 8px;
    flex: 0 1 calc(25% - 15px);
    text-align: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.skill:hover {
    transform: scale(1.05);
    background-color: #d0d0d0;
}

.skill h4 {
    font-size: 1.2em;
    margin-bottom: 8px;
    color: #0d6efd;
}

.skill p {
    font-size: 0.9em;
}

/* Projektbereich */
.project-section {
    padding: 40px 0;
    background-color: #333; /* Hintergrund für den Projektbereich */
    color: #eee;
}

.project-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Zentriert die Boxen */
    max-width: 1200px; /* Begrenzung der maximalen Breite für das Grid */
    margin: 0 auto; /* Zentriert das Grid auf der Seite */
    padding: 0 20px; /* Seitlicher Abstand für schmalere Bildschirme */
}

.project {
    flex: 1 1 45%;
    min-width: 300px;
    max-width: 450px; /* Maximale Breite jeder Projektbox */
    padding: 20px;
    border: 1px solid #444; /* Sichtbare Umrandung */
    border-radius: 8px;
    background-color: #222; /* Hintergrundfarbe der Projektbox */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Übergang für Hover */
    text-align: center; /* Text zentriert in der Box */
}

.project h4 {
    margin: 0 0 10px;
    font-size: 1.2em;
    color: #0f0; /* Hervorgehobene Überschrift */
}

.project p {
    font-size: 0.9em;
    color: #ddd;
    line-height: 1.5;
}

/* Hover-Effekt für die Projekte */
.project:hover {
    transform: scale(1.05); /* Leichte Vergrößerung */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Schatteneffekt */
}

/* Kontakt-Bereich */
.contact {
    margin-top: 30px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact h3 {
    text-align: center;
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;
    margin: 0 auto;
}

.contact-form label {
    font-weight: bold;
    color: #333;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    resize: vertical;
}

.contact-form button {
    padding: 10px 20px;
    background-color: #0d6efd;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #0044cc;
}

/* Footer */
footer {
    text-align: center;
    padding: 15px;
    background-color: #333;
    color: #fff;
    margin-top: 30px;
    border-top: 4px solid #0d6efd;
}

footer p {
    font-size: 0.9em;
}

